import moment from 'moment';

import { InputValueType } from '@/interfaces/search';

export const getDate = (date: string) => moment(date).format('YYYY-MM-DD HH:mm:ss');

export const formatDay = (date: InputValueType) => {
  if (typeof date === 'string' || moment.isMoment(date)) {
    return moment(date).format('YYYY-MM-DD');
  }
  return '';
};

export const formatMonth = (date: InputValueType) => {
  if (typeof date === 'string' || moment.isMoment(date)) {
    return moment(date).format('YYYY-MM');
  }
  return '';
};

export const formatDateTime = (date: InputValueType) => {
  if (typeof date === 'string' || moment.isMoment(date)) {
    return moment(date).format('YYYY-MM-DDTHH:mm:ss');
  }
  return '';
};

export const parseDate = (date: string) => {
  return parse(date, 'YYYY-MM-DD');
};

export const parseDateTime = (date: string) => {
  return parse(date, 'YYYY-MM-DDTHH:mm:ss');
};

export const parse = (date: string, format: string) => {
  if (date && format) {
    return moment(date, format);
  }
  return null;
};
