import uniq from 'lodash/uniq';

import { PRIVILEGE_CODE } from '@/constant/privilege';
import { SearchField, SearchRenderField } from '@/interfaces/search';

export const TAG = {
  INPUT: 'INPUT',
  SELECT: 'SELECT',
  DATE: 'DATE',
  SWITCH: 'SWITCH',
  RADIO: 'RADIO',
  FILE_UPLOAD: 'FILE_UPLOAD',
  MONTH: 'MONTH',
  PERIOD: 'PERIOD',
  SELECT_AND_DATE: 'SELECT_AND_DATE',
  DATE_TIME: 'DATE_TIME',
  SINGLE_SELECT: 'SINGLE_SELECT',
  SINGLE_DATE: 'SINGLE_DATE',
};

export enum FieldTagEnum {
  INPUT = 'INPUT',
  SELECT = 'SELECT',
  DATE = 'DATE',
  SWITCH = 'SWITCH',
  RADIO = 'RADIO',
  FILE_UPLOAD = 'FILE_UPLOAD',
  MONTH = 'MONTH',
  PERIOD = 'PERIOD',
  SELECT_AND_DATE = 'SELECT_AND_DATE',
  DATE_TIME = 'DATE_TIME',
  SINGLE_SELECT = 'SINGLE_SELECT',
  SINGLE_DATE = 'SINGLE_DATE',
}

export const DATA_SOURCE = {
  GO: 'GO',
  CESAR: 'CESAR',
  VLMS: 'VLMS',
  SOP: 'SOP',
  OTR: 'OTR',
  Commerce: 'Commerce',
  VPS: 'VPS',
  Others: 'Others',
  DMD: 'DMD',
  BBAC: 'BBAC',
  DSP: 'DSP',
};

export const FieldDataSourceOrder = Object.values(DATA_SOURCE);

export const getDataSources = (fieldList: (SearchField | SearchRenderField)[]) => {
  return uniq(fieldList.map((field) => field.dataSource)).sort(
    (a, b) => FieldDataSourceOrder.indexOf(a) - FieldDataSourceOrder.indexOf(b)
  );
};

export const DOMAIN = {
  ModelD: 'Model D',
  Vehicle: 'Vehicle',
  Order: 'Order',
  Production: 'Production',
  Logistics: 'Logistics',
  Dealer: 'Dealer',
  Agent: 'Agent',
  Wholesale: 'Wholesale',
  Retail: 'Retail',
  Price: 'Price',
  DigitalSales: 'Digital Sales',
  SalesOrder: 'Sales Order',
  BBAC: 'BBAC',
  Block: 'Block',
  RetailOrder: 'Retail Order',
};

export const FieldDomainOrder = Object.values(DOMAIN);

export const DOMAIN_FOR_DISPLAY = {
  ...DOMAIN,
  WholesaleAndRetail: 'Wholesale & Retail',
};

export const DOWNLOAD_MODE = {
  DOMAIN: 'domain',
  DATA_SOURCE: 'data source',
};

export const SAVE_OR_UPDATE_ACTION = {
  SAVE: 1,
  UPDATE: 2,
  NOTHING: 3,
};

export const OPERATION = {
  INCLUDE: 'INCLUDE',
  UPLOAD: 'UPLOAD',
  EXCLUDE: 'EXCLUDE',
};

export const DATE_TYPE = {
  INPUT_RANGE: 'input range',
  EXCLUDE: 'exclude',
  BLANK: 'blank',
  NOT_BLANK: 'not blank',
};

export const DATE_TYPE_VALUE = {
  RANGE: 'RANGE',
  BLANK: 'BLANK',
  NOT_BLANK: 'NOT_BLANK',
};

export const GROUP_COLOR: Record<string, string> = {
  GO: '#79ECD4',
  CESAR: '#B88DDF',
  VLMS: '#F3CE00',
  SOP: '#F88922',
  OTR: '#E7417D',
  Commerce: '#B6C5DB',
  VPS: '#D842E8',
  Others: '#2F80ED',
  DMD: '#2F38EDFF',
  BBAC: '#D5E057',
  DSP: '#48e63d',

  Vehicle: '#79ECD4',
  Order: '#B88DDF',
  Production: '#F3CE00',
  Logistics: '#2F80ED',
  Dealer: '#F178B6',
  Agent: '#F178B6',
  Wholesale: '#5AB0FF',
  Retail: '#508768',
  WholesaleAndRetail: '#5AB0FF',
  ModelD: '#C56BD4',
  Price: '#58EE5E',
  DigitalSales: '#D5E057',
  SalesOrder: '#B6C5DB',
  RetailOrder: '#00E5EE',
  Block: '#CC8C35',
};

export const HTTP_CODE = {
  MULTI_STATUS: 207,
  UNAUTHORIZED: 401,
  BAD_REQUEST: 400,
  NOT_FOUND: 404,
  INTER_SERVE_ERROR: 500,
  FORBIDDEN: 403,
};

export const SEARCH_SORT: Record<string, string[]> = {
  Vehicle: ['Commission No.', 'VIN No.', 'FIN No.', 'Configuration', 'Paint Lower', 'Paint Upper'],
  Order: [
    'Invalid',
    'GO Archive',
    'CBU To-Be Cancel List',
    'CBU Available In Current Month',
    'Produced',
    'Departure From Dispatch',
    'Arrival At Destination Port',
    'Goods Receipt',
    'PDI OK',
    'Allocated To Dealers',
    'Wholesale',
    'Retail',
  ],
  Production: [
    'Allocation Period',
    'Final Inspection Date',
    'Scheduled Final Inspection Date',
    'Actual Final Inspection Date',
    'Factory',
    'Factory number',
  ],
  Logistics: [
    'Primary Destination Key',
    'Final Destination Key',
    'Current Location',
    'PDI Status',
    'Expected Arrival Date At Destination Port',
    'ETA',
    'ATA',
    'PDI OK Date',
  ],
  Dealer: ['Dealer Code', 'Dealer Name', 'Dealer Name CN', 'Region', 'Sub Region', 'Dealer Group'],
  'Wholesale & Retail': ['Billing Date', 'Retail Date'],
  Agent: ['Agent Code', 'Agent Name', 'Agent Name CN', 'Region', 'Sub Region', 'Agent Group'],
};

export const TEMPLATE_TYPE = {
  COMMON: 'COMMON_TEMPLATE',
  USER: 'USER_TEMPLATE',
};

export const SEARCH_DOWNLOAD_MODAL = {
  SEARCH_AND_DOWNLOAD: 'searchAndDownload',
  DOWNLOAD_CONFIRM: 'downloadConfirm',
  SAVE_TEMPLATE: 'saveTemplate',
  SAVE_OR_UPDATE_TEMPLATE: 'saveOrUpdateTemplate',
};

export const VEHICLE_DETAIL = {
  FIELD_SELECTION: 'fieldSelection',
};

export const SAVE_AND_DOWNLOAD_TIPS = {
  SUCCESS_TIPS: 'Search & downloaded successfully',
  FAIL_TIPS: 'Failed to download, please try again later',
};

export const SOURCE = {
  AIA: 'AIA',
};

export const LOG_STATUS: Record<string, { type: string; text: string }> = {
  SUCCESS: { type: 'success', text: 'Success' },
  FAIL: { type: 'danger', text: 'Failed' },
  PARTIAL: { type: 'warning', text: 'Partial Success' },
};

export enum OperationScopeEnum {
  ALL_VEHICLE = 'All Vehicles',
  MODEL_D = 'Model D',
}

export enum SearchOrDownloadEnum {
  SEARCH = 'Search',
  DOWNLOAD = 'Download',
}

export const NAV_ITEM = {
  fileUploadNavItem: {
    title: 'File Upload',
    link: '/home/upload',
    privileges: [
      PRIVILEGE_CODE.FILE_UPLOAD_ETA_VESSEL_NAME,
      PRIVILEGE_CODE.FILE_UPLOAD_TO_BE_CANCEL_LIST,
      PRIVILEGE_CODE.FILE_UPLOAD_PBP_ALLOCATION_BILLING,
      PRIVILEGE_CODE.FILE_UPLOAD_MODELD_VU_MANAGEMENT,
      PRIVILEGE_CODE.FILE_UPLOAD_CCC_CERTIFICATION,
      PRIVILEGE_CODE.FILE_UPLOAD_DIGITAL_SALES,
      PRIVILEGE_CODE.FILE_UPLOAD_CESAR_WS,
      PRIVILEGE_CODE.FILE_UPLOAD_CESAR_CANCEL_WS,
      PRIVILEGE_CODE.FILE_UPLOAD_CESAR_RT,
      PRIVILEGE_CODE.FILE_UPLOAD_PBP_FORWARD_TRANSPORT,
      PRIVILEGE_CODE.FILE_UPLOAD_CESAR_CANCEL_RT,
      PRIVILEGE_CODE.FILE_UPLOAD_OPTION_PACKAGE,
      PRIVILEGE_CODE.FILE_UPLOAD_OPTION_PACKAGE_V2,
      PRIVILEGE_CODE.FILE_UPLOAD_PT_CAR_PRICE,
      PRIVILEGE_CODE.FILE_UPLOAD_CONTRACT_READY_MANAGEMENT,
      PRIVILEGE_CODE.FILE_UPLOAD_OEM_MANUAL_ALLOCATION_MANAGEMENT,
      PRIVILEGE_CODE.FILE_UPLOAD_MANUAL_REPLACEMENT,
      PRIVILEGE_CODE.FILE_UPLOAD_STOCK_TAKING_AT_AGENT,
      PRIVILEGE_CODE.FILE_UPLOAD_MAINTAIN_FAPIAO_MAILING_INFO,
      PRIVILEGE_CODE.FILE_UPLOAD_BLOCK_MANAGEMENT,
    ],
  },
  searchNavItem: {
    title: 'Search',
    link: '/home/search',
    privileges: [PRIVILEGE_CODE.SEARCH, PRIVILEGE_CODE.BTO_SEARCH],
  },
  reportNavItem: {
    title: 'Sales Operation & Report',
    link: '/home/report',
    privileges: [
      PRIVILEGE_CODE.ALLOCATION_APPLICATION,
      PRIVILEGE_CODE.POPT,
      PRIVILEGE_CODE.VEHICLE_CHANGE_LOG,
      PRIVILEGE_CODE.PT_CAR_PRICE_REPORT,
      PRIVILEGE_CODE.OPTION_PACKAGE_MAPPING_REPORT,
      PRIVILEGE_CODE.STOCK_DAY_DISPLAY_PERIOD_MANAGEMENT,
      PRIVILEGE_CODE.CBU_WS_RATIO_MANAGEMENT,
      PRIVILEGE_CODE.PBP_WS_RATIO_MANAGEMENT,
      PRIVILEGE_CODE.STOCK_TAKING_HISTORY,
      PRIVILEGE_CODE.BLOCK_MANAGEMENT_REPORT,
      PRIVILEGE_CODE.PRIVILEGE_PRICE_FUNCTION,
      PRIVILEGE_CODE.PRIVILEGE_TAKE_RATE_REPORT,
      PRIVILEGE_CODE.PRIVILEGE_STOCK_MATCHING_TOOL,
      PRIVILEGE_CODE.PRIVILEGE_DIGITAL_SALES_FUNNEL_REPORT,
    ],
  },
  userGuidelineNavItem: { title: 'User Guideline', link: '/home/user-guide' },
  uamNavItem: { title: 'UAM', link: '/home/uam', privileges: [PRIVILEGE_CODE.UAM] },
  logItem: { title: 'Log', link: '/home/log', privileges: [PRIVILEGE_CODE.LOG] },
};

export const SEARCH_SUB_NAV_ITEM = {
  physicalVehicleSearchNavItem: {
    title: 'Physical Vehicle',
    link: '/home/search',
    privileges: [PRIVILEGE_CODE.SEARCH],
  },
  btoSearchNavItem: { title: 'BTO Order', link: '/home/bto-search', privileges: [PRIVILEGE_CODE.BTO_SEARCH] },
};

export enum WSRatioStatusEnum {
  HISTORICAL = 'Historical',
  VALID = 'Valid',
  TO_BE_VALID = 'To be valid',
}

export const WS_RATIO_CITY_LIST = ['Shanghai'];

export const NOTIFICATION_LINK = '/home/notification';

export const NOTIFICATION_TO_DOS_TITLE = 'To-Dos';
export const NOTIFICATION_TO_DOS_ITEM = [
  {
    typeCode: 'PRE_CONTRACT_ALERT',
    privilege: PRIVILEGE_CODE.PRIVILEGE_NOTIFICATION_PRE_CONTRACT_ALERT,
  },
  {
    typeCode: 'ONLINE_REVERSED_STOCK',
    privilege: PRIVILEGE_CODE.PRIVILEGE_NOTIFICATION_ONLINE_REVERSED_STOCK,
  },
  {
    typeCode: 'POST_CONTRACT_ALERT',
    privilege: PRIVILEGE_CODE.PRIVILEGE_NOTIFICATION_POST_CONTRACT_ALERT,
  },
  {
    typeCode: 'FAPIAO_REISSUE_REQUEST',
    privilege: PRIVILEGE_CODE.PRIVILEGE_NOTIFICATION_FAPIAO_REISSUE_ALERT,
  },
  {
    typeCode: 'CONFIRMED_FAPIAO_RECEIVED',
    privilege: PRIVILEGE_CODE.PRIVILEGE_NOTIFICATION_CONFIRMED_FAPIAO_RECEIVED,
  },
];
export enum NotificationToDosStatusEnum {
  TO_DO = 'To-do',
  PENDING = 'Pending',
  DONE = 'Done',
}

export enum NotificationFapiaoStatusEnum {
  TO_DO = 'To-do',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
  DONE = 'Done',
}

export const NOTIFICATION_OPERATION_STATUS_TITLE = 'Operation Status';
export const NOTIFICATION_OPERATION_STATUS_ITEM = [
  {
    typeCode: '',
    privilege: '',
  },
];

export const NOTIFICATION_DOWNLOAD_CENTER_TITLE = 'Download Center';

export enum DownloadCenterStatusEnum {
  PENDING = 'Pending',
  IN_PROGRESS = 'In-progress',
  DONE = 'Done',
  FAILED = 'Failed',
}
