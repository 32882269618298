import { defineStore } from 'pinia';

interface LoadingState {
  isLoading: boolean;
  text: string;
}

export const useLoadingStore = defineStore('loading', {
  state: (): LoadingState => ({
    isLoading: false,
    text: '',
  }),
  actions: {
    updateLoadingStatus(status: boolean) {
      this.isLoading = status;
    },
  },
});
