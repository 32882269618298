import api from '@/api';
import { PageResponse, Response } from '@/interfaces/common';
import { Privileges, Role } from '@/interfaces/uam';

export interface RoleRequest {
  name: string;
  owner: string;
  description: string;
  functionPrivilegeList: number[];
  dataScopePrivilegeList: number[];
  orderDataScopePrivilegeList: number[];
}

export interface UserResponse {
  id: number;
  wiwAccountId: string;
  email: string;
  createAt: string;
  roleList: Pick<Role, 'id' | 'name'>[];
}

export interface ValidationUserResponse {
  emailExist: boolean;
  wiwAccountExist: boolean;
}

export default {
  getAllPrivileges: (): Promise<Response<Privileges>> => api.instance.get('/api/user-service/privileges'),

  getRolesByPage: (current: number, pageSize: number): Promise<PageResponse<Role>> =>
    api.instance.get('/api/user-service/roles', {
      params: {
        pageIndex: current,
        pageSize,
      },
    }),
  saveRole: (id: number, role: RoleRequest): Promise<Response<null>> => {
    const baseUrl = '/api/user-service/roles';
    return id ? api.instance.put(`${baseUrl}/${id}`, role) : api.instance.post(baseUrl, role);
  },
  checkRoleNameExists: (name: string): Promise<Response<boolean>> =>
    api.instance.get('/api/user-service/roles/validation', { params: { name } }),
  getAllRoleNames: (): Promise<Response<Pick<Role, 'id' | 'name'>[]>> =>
    api.instance.get('/api/user-service/roles/options'),

  getUserByPage: (current: number, pageSize: number, keyword: string): Promise<PageResponse<UserResponse>> =>
    api.instance.get('/api/user-service/users', {
      params: {
        pageIndex: current,
        pageSize,
        keyword,
      },
    }),
  // eslint-disable-next-line camelcase
  validationUser: (user: { wiw_account: string; email: string }): Promise<Response<ValidationUserResponse>> =>
    api.instance.get('/api/user-service/users/validation', { params: user }),
  deleteUsers: (ids: number[]) => api.instance.delete('/api/user-service/users', { params: { ids: `${ids}` } }),
  saveUser: (userId: number, user: { wiwAccountId: string; mail: string; roleList: number[] }) => {
    const baseUrl = '/api/user-service/users';
    return userId ? api.instance.put(`${baseUrl}/${userId}`, user) : api.instance.post(baseUrl, user);
  },
};
