import Vue from 'vue';

// eslint-disable-next-line import/order
import pinia from './stores';
import App from './App.vue';
import router from './router';

import '@/utilities/antd';
import '@/utilities/element-ui';
import '@/utilities/umy-ui';
import '@/utilities/dayjs';
import '@/utilities/tingyun';

import '@/assets/icon/iconfont.css';
import '@/assets/style/global.scss';

Vue.config.productionTip = false;

new Vue({
  router,
  pinia,
  render: (h) => h(App),
}).$mount('#app');
