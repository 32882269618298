import {
  Button,
  Row,
  Col,
  Spin,
  Radio,
  Input,
  Modal,
  Form,
  FormModel,
  Checkbox,
  Switch,
  Select,
  DatePicker,
  Icon,
  Layout,
  Anchor,
  Breadcrumb,
  Table,
  Dropdown,
  Menu,
  Tabs,
  Popover,
  Pagination,
  Empty,
  Progress,
  Divider,
  Card,
} from 'ant-design-vue';
import Vue from 'vue';

Vue.use(Button);
Vue.use(Row);
Vue.use(Col);
Vue.use(Spin);
Vue.use(Radio);
Vue.use(Input);
Vue.use(Modal);
Vue.use(Form);
Vue.use(FormModel);
Vue.use(Checkbox);
Vue.use(Switch);
Vue.use(Select);
Vue.use(DatePicker);
Vue.use(Icon);
Vue.use(Layout);
Vue.use(Anchor);
Vue.use(Breadcrumb);
Vue.use(Table);
Vue.use(Dropdown);
Vue.use(Menu);
Vue.use(Tabs);
Vue.use(Popover);
Vue.use(Pagination);
Vue.use(Empty);
Vue.use(Progress);
Vue.use(Divider);
Vue.use(Card);
