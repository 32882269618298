import Tingyun, { ConfigOptions } from '@tingyun-sdk/web';

const configs: Record<string, Pick<ConfigOptions, 'token' | 'key' | 'id'>> = {
  'qa-salesservice.mercedes-benz.com.cn': {
    token: '699677cf400040dd9a119d1a02fc6d4c',
    key: 'Tm5lNnsWPbU',
    id: '8Gf2qY_v0aE',
  },
  'salesservice-uat.mercedes-benz.com.cn': {
    token: '0277e7136b104fe09c93179470eaca28',
    key: 'e8eMIiwzyLU',
    id: '8Gf2qY_v0aE',
  },
  'salesservice.mercedes-benz.com.cn': {
    token: 'a13c9928cbfe4da98d1d89a7b401574a',
    key: 'Z0_gq73tfr0',
    id: '8Gf2qY_v0aE',
  },
};

const config = configs[document.domain];
if (config) {
  Tingyun.init({
    domain: 'wkbrs2.tingyun.com',
    token: config.token,
    key: config.key,
    id: config.id,
    operation: { rageClickEnabled: false },
    replay: { sampleRate: 0.1 },
  });
}
