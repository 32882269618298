import { AxiosPromise } from 'axios';

import api from '@/api';
import { ResponseResult } from '@/interfaces/common';

export default {
  login: (data: { userId: string; password: string }): AxiosPromise<ResponseResult<{ jwtToken: string }>> =>
    api.instance.post('/api/login', data),
  getCurrentUser: () => api.pureInstance.get('/api/user-service/users/current'),
  ssoLogin: () => api.instance.get('/api/user-service/sso/login-url'),
  ssoLogout: () => api.instance.get('/api/user-service/sso/logout-url'),
  getTokenByOpenIdCode: (body: { code: string }) => api.instance.post('/api/user-service/sso:exchange-token', body),
};
