import { defineStore } from 'pinia';

import loginApi from '@/api/login';
import api from '@/api/uam';
import { Privileges, Role, User } from '@/interfaces/uam';

interface UamState {
  user?: User;
  privileges: Privileges;
  roles: Pick<Role, 'id' | 'name'>[];
}

export const useUamStore = defineStore('uam', {
  state: (): UamState => ({
    user: undefined,
    privileges: {
      functionPrivilege: [],
      dataScopePrivilege: [],
      attributePrivilege: [],
      searchPrivilege: [],
    },
    roles: [],
  }),
  actions: {
    async getAllPrivileges() {
      const { data: res } = await api.getAllPrivileges();
      this.privileges = res.data;
    },
    async getRoles() {
      const { data: res } = await api.getAllRoleNames();
      this.roles = res.data;
    },
    async getUserInfo() {
      const { data: res } = await loginApi.getCurrentUser();
      const privileges = res.data.roleList.flatMap((item: Role) => item.functionPrivilegeList);
      this.user = { ...res.data, privileges };
    },
  },
});
