import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import { HTTP_CODE } from '@/constant/config';
import { useOperationScopeStore } from '@/stores/operationScope';
import { toLoginPage } from '@/utilities/common';

const instance = axios.create();
const pureInstance = axios.create();

const requestInterceptor = (config: AxiosRequestConfig) => {
  const operationScopeState = useOperationScopeStore();
  const token = localStorage.getItem('token');
  if (token && config.headers) {
    config.headers['Authorization'] = `Bearer ${token}`;
    config.headers['operationScope'] = operationScopeState.operationScope;
  }
  return config;
};

instance.interceptors.request.use(requestInterceptor);
pureInstance.interceptors.request.use(requestInterceptor);

const onResponseFulfilled = (response: AxiosResponse) => {
  if (response?.headers?.['renew-token']) {
    localStorage.setItem('token', response.headers['renew-token']);
  }
  return response;
};

const onResponseRejected = (error: Error | AxiosError) => {
  if (axios.isAxiosError(error)) {
    const { response } = error;
    if (response?.status === HTTP_CODE.UNAUTHORIZED) {
      toLoginPage();
      return Promise.reject(response);
    }
    if (response?.headers?.['renew-token']) {
      localStorage.setItem('token', response.headers['renew-token']);
    }
    return Promise.reject(response);
  }
  return Promise.reject(error);
};

instance.interceptors.response.use(onResponseFulfilled, onResponseRejected);
pureInstance.interceptors.response.use(onResponseFulfilled);

export default {
  instance,
  pureInstance,
};
