
import { defineComponent } from 'vue';

import { useLoadingStore } from '@/stores/loading';
import { useUamStore } from '@/stores/uam';

export default defineComponent({
  setup() {
    const loadingStore = useLoadingStore();

    useUamStore().$subscribe(
      (mutation, state) => {
        if (state.user?.userId) {
          localStorage.setItem('TY_USER_ID', state.user.userId);
          return;
        }
        localStorage.removeItem('TY_USER_ID');
      },
      { detached: true, deep: true }
    );

    return {
      loadingStore,
    };
  },
});
