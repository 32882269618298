import { defineStore } from 'pinia';

import { OperationScopeEnum } from '@/constant/config';

interface OperationScopeState {
  operationScope: OperationScopeEnum;
}

export const useOperationScopeStore = defineStore('operationScope', {
  persist: true,
  state: (): OperationScopeState => ({
    operationScope: OperationScopeEnum.ALL_VEHICLE,
  }),
  actions: {
    updateOperationScope(types: OperationScopeEnum[]) {
      if (!types.includes(this.operationScope)) {
        this.operationScope = types[0];
      }
    },
  },
});
